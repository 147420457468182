<template>
  <section class="edit-checklist">
    <row container :gutter="20">
      <column :xs="12" :md="6" :lg="6">
        <CheckList
            v-for="(item, index) in checklists"
            :editable="true"
            :checklist="item"
            :key="index"
            @deleteChecklist="deleteChecklist"
          ></CheckList>
				<Button
          v-if="checklists.length && isCabinOwner() || isCabinAdministrator() && !editChecklistMode && !addChecklistMode"
          id="addCheckListButton"
          symbol="plus"
          text="Opprett ny liste"
          color="green"
          size="full"
          @click="addChecklist()"
        ></Button>		
      </column>
    </row>
  </section>
</template>
<script>
import CheckList from '@/components/CheckList';
import ChecklistService from '@/services/ChecklistService';
import Button from '@/components/Button';

export default {
  name: 'editChecklists',
	components: {
    CheckList,
		Button
  },
	props: {
    cabinUserRoleId: Number,
    cabin: Object
  },
	data() {
    return {
      checklists: []
    }
  },
	methods: {
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },

    addChecklist() {
      this.$router.push({ name: 'addChecklist' })
    },
    deleteChecklist(val) {
      let deleteListPosition = this.checklists.findIndex((item) => {
        return item.id === val;
      });

      this.checklists.splice(deleteListPosition, 1);
      
      if(this.checklists.length == 0) {
        this.$router.push({ name: 'Checklists'});
      }
    }
  },
	async beforeRouteEnter(to, from, next) {
    const { data } = await ChecklistService.getChecklists(to.params.cabinId);
    next((vm) => {
      if((vm.isCabinAdministrator() || vm.isCabinOwner())) {
        if (data) {
          vm.checklists = data;
        }
      } else {
        vm.$router.push(from);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
 .edit-checklist {
    @include gutter-padding;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 6rem;
    }
    @media (min-width: $breakpoint-mobile) {
      margin: 4rem 0;
    }
	}
</style>